import { InputBase, Stack, TextField, styled } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import palette from '../theme/palette';

export const InputContainer = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  borderRadius: '0.4rem',
  padding: '0.2rem 0.4rem',
  backgroundColor: palette.visitor.input.bg,
}));

export const StyledInput = styled(InputBase)(({ theme }) => ({
  marginLeft: 8,
  fontSize: '0.8rem',
  fontWeight: 900,
  fontFamily: 'sans-serif',
  flexGrow: 1,
  color: palette.visitor.input.color,
  input: {
    '&::placeholder': {
      opacity: 0.9,
      color: palette.visitor.input.placeholder,
      fontWeight: 900,
    },
  },
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  // fontSize: '0.8rem',
  // fontWeight: 900,
  fontFamily: 'sans-serif',
  flexGrow: 1,
  color: palette.visitor.input.color,
  border: 'none',
  input: {
    fontSize: '0.8rem',
    fontWeight: 900,
    '&::placeholder': {
      opacity: 0.9,
      color: palette.visitor.input.placeholder,
      fontWeight: 900,
      fontSize: '0.8rem',
    },
  },
}));

export const ThemeButton = styled(LoadingButton)(({ theme }) => ({
  background: palette.button.bg,
  color: palette.visitor.login.color,
  borderRadius: '10px',
  border: palette.button.border,
  boxShadow: palette.button.shadow,
  fontSize: '0.8rem',
  '&: disabled': {
    boxShadow: 'none'
  }
}));

export const TableHeadCustomStyle = {
  backgroundColor: 'black',
  borderCollapse: 'separate',
  '& th': {
    borderTop: '1px solid white',
    borderBottom: '1px solid white',
    color: 'white',
  },
  '& th:first-of-type': {
    paddingLeft: 2,
    borderLeft: '1px solid white',
    borderTop: '1px solid white',
    borderBottom: '1px solid white',
  },
  '& th:last-of-type': {
    paddingRight: 2,
    borderRight: '1px solid white',
    borderTop: '1px solid white',
    borderBottom: '1px solid white',
  },
};
